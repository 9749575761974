import React from "react";
import { Link } from "react-router-dom";
import './notFound.css'

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="error-box">
        <h1 className="error-code">404</h1>
        <p className="error-message">Sorry, the page you're looking for doesn't exist.</p>
        <Link to="/" className="home-link">🏠 Back to Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
